import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"

import SEO from "../components/seo"

import Button from "../components/button"
import { Container } from "../components/styledcomponents"
import globals from "../components/globals"
import logo from "../images/TLA-Logo.svg"

const H1 = styled.h1`
  margin: 0;
  color: ${globals.color.lightShades};
  &&:after {
    display: block;
    content: "";
    border: solid ${globals.color.main};
    width: 3em;
  }
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const Links = () => (
  <>
    <SEO
      title="Linksammlung"
      description="Eine Sammlung aktueller Links und Posts"
    />
    <Container
      width="100vw"
      height="100vh"
      padding="0"
      margin="0"
      justifyContent="space-around"
      backgroundColor="#fff"
      backgroundImage={`linear-gradient(-135deg,${globals.color.darkShades},${globals.color.lightAccent},${globals.color.darkAccent})`}
    >
      <Link to="/" rel="home">
        <img
          width="80"
          height="80"
          src={logo}
          alt="Das Team Laurien &amp; Alex - BJJ Yoga Selbstvertidigung Logo"
        />
      </Link>
      <Link to="/" rel="home">
        <H1>Team Laurien & Alex</H1>
      </Link>
      <Button
        to="https://www.off-the-zone.com/armbalances"
        margin="0"
        width="70vw"
        maxWidth="400px"
      >
        Armbalance Yoga Kurs
      </Button>
      <Button
        to="https://www.off-the-zone.com/kickboxsack-training-kurs"
        margin="0"
        width="70vw"
        maxWidth="400px"
      >
        Boxsacktraining Kurs
      </Button>
      <Button to="/stundenplan/" margin="0" width="70vw" maxWidth="400px">
        Stundenplan
      </Button>
      <Button
        to="/stundenplan/"
        margin="0"
        width="70vw"
        maxWidth="400px"
      >
        Kostenlose Probestunde buchen
      </Button>
      <Flex>
        <Link to="/datenschutzerklaerung/">Datenschutzerklärung</Link>
        <Link to="/impressum/">Impressum</Link>
      </Flex>
    </Container>
  </>
)

export default Links
